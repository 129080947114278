import { MagnifyingGlassIcon } from '@heroicons/react/20/solid';
import { FC } from 'react';

interface Props {
    search: string;
    setSearch: (a: string) => void;
    submit: () => void;
    className?: string;
    placeholder?: string;
}

const SearchBarInput: FC<Props> = ({
    search,
    setSearch,
    submit,
    className,
    placeholder = 'Buscar...',
}) => {
    const handleKeyDown = (event: any) => {
        if (event.key === 'Enter') {
            submit(); // Trigger search when Enter is pressed
        }
    };

    return (
        <div className={`w-full sm:w-96 ${className}`}>
            {/* <label
        htmlFor="email"
        className="block text-sm font-medium text-gray-700"
      >
        Search candidates
      </label> */}
            <div className="flex mt-1 rounded-md shadow-sm">
                <div className="flex relative flex-grow items-stretch focus-within:z-10">
                    <div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                        <MagnifyingGlassIcon className="w-5 h-5 text-gray-400" aria-hidden="true" />
                    </div>
                    <input
                        type="text"
                        name="search"
                        id="search"
                        className="block pl-10 w-full rounded-none rounded-l-md border-gray-300 focus:border-accent-600 focus:ring-accent-600 sm:text-sm"
                        placeholder={placeholder}
                        value={search}
                        onKeyDown={handleKeyDown}
                        onChange={(e) => setSearch(e.target.value)}
                    />
                </div>
                <button
                    type="button"
                    className="inline-flex relative items-center px-4 py-2 -ml-px space-x-2 text-sm font-bold text-white rounded-r-md border border-accent-400 bg-accent-400 hover:bg-accent-600 focus:border-accent-600 focus:outline-none focus:ring-1 focus:ring-accent-600"
                    onClick={submit}
                >
                    <span>Buscar</span>
                </button>
            </div>
        </div>
    );
};

export default SearchBarInput;
