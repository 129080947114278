import { useEffect, useState } from 'react';
import ReactPlayer from 'react-player';
import { AccentButton, AdminAdvertisingLayout, SearchBarInput } from '../../../common/components';
import LogDetailsModal from '../logs/LogDetailsModal';
import { useRequests } from '../../../common/hooks';

const AdvertisingPreview = () => {
    const [currentMedia, setCurrentMedia] = useState<any>();

    const { post } = useRequests();
    const [mediaItems, setMediaItems] = useState<any[]>([]);
    const [search, setSearch] = useState('');
    const [jsonData, setJsonData] = useState({});

    const submitSearch = async () => {
        try {
            const response = await post('/cloud/screen/plan', {
                sn: search,
            });

            if (!response) return;

            const _data = response?.data;
            const _mediaItems = _data?.list?.[0]?.list.map((item: any) => ({
                type: item.type,
                src: item.file,
            }));
            const _currentMedia = _mediaItems?.[0];

            setJsonData(_data);
            setMediaItems(_mediaItems);
            setCurrentMedia(_currentMedia);
        } catch (err) {}
    };

    // TODO: open modal with json data for debug
    const [detailsModalOpen, setDetailsModalOpen] = useState(false);

    const openDetailsModal = () => {
        setDetailsModalOpen(true);
    };

    const closeDetailsModal = () => {
        setDetailsModalOpen(false);
    };

    useEffect(() => {
        submitSearch();
        // eslint-disable-next-line
    }, []);

    return (
        <AdminAdvertisingLayout title="Preview conteúdos de publicidade">
            <div className="flex justify-between items-center mb-8">
                <SearchBarInput
                    placeholder="Buscar serial number..."
                    search={search}
                    setSearch={setSearch}
                    submit={submitSearch}
                />
                <div>
                    <AccentButton className="h-[38px] px-6" onClick={openDetailsModal}>
                        Detalhes
                    </AccentButton>
                </div>
            </div>

            <div className="flex flex-row">
                {/* Main Media Display */}
                <div className="flex justify-center items-center mr-4 w-[70%]">
                    <div className="w-[500px] h-[400px]">
                        {currentMedia?.type === 'image' ? (
                            <img
                                src={currentMedia?.src}
                                alt="Current Media"
                                className="max-w-full max-h-full"
                            />
                        ) : (
                            <ReactPlayer
                                url={currentMedia?.src}
                                controls
                                width="100%"
                                height="100%"
                            />
                        )}
                    </div>
                </div>

                {/* Lateral Carousel */}
                <div className="flex-1 overflow-auto bg-gray-200 p-2 flex flex-col gap-4 max-h-[60vh] ml-2">
                    {mediaItems.map((item, index) => (
                        <div
                            key={index}
                            onClick={() => setCurrentMedia(item)}
                            className={`cursor-pointer p-1 border-4 ${
                                currentMedia === item ? 'border-blue-500' : 'border-gray-400'
                            }`}
                        >
                            {item.type === 'image' ? (
                                <img
                                    src={item.src}
                                    alt={`Thumbnail ${index}`}
                                    className="object-cover w-full h-auto"
                                />
                            ) : (
                                <div className="p-2 text-center text-white bg-black">
                                    Video {index + 1}
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            </div>
            <LogDetailsModal
                open={detailsModalOpen}
                closeModal={closeDetailsModal}
                details={jsonData}
                collapsed={false}
            />
        </AdminAdvertisingLayout>
    );
};

export default AdvertisingPreview;
