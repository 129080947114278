import { yupResolver } from '@hookform/resolvers/yup';
import { FC, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAdvertisingContents } from '../../../common/hooks';
import { payloadFromSchema, schema } from './CreateAdvertisingContent';
import AdvertisingContentForm from './AdvertisingContentForm';
import { intSecondsToFormattedMinutes } from '../../../common/utils/date.utils';

const createFormData = (data: any) => {
    const payload = {
        ...data,
        duration: intSecondsToFormattedMinutes(data.duration),
    };

    return payload;
};

interface Props {
    orgId?: string;
}

const UpdateAdvertisingContent: FC<Props> = ({ orgId }) => {
    const { updateAdvertisingContent, loading, error } = useAdvertisingContents(orgId);
    const navigate = useNavigate();
    const location = useLocation();

    const advertisingContentData = location.state?.data;

    const [file, setFile] = useState<any>();

    const {
        control,
        handleSubmit,
        formState: { errors },
        setValue,
        reset,
    } = useForm({
        resolver: yupResolver(schema),
        defaultValues: useMemo(
            () => advertisingContentData && createFormData(advertisingContentData),
            [advertisingContentData],
        ),
    });

    useEffect(() => {
        if (advertisingContentData) {
            reset(createFormData(advertisingContentData));
        }
        // eslint-disable-next-line
    }, []);

    const onSubmit = async (data: any) => {
        const payload = payloadFromSchema(data, file);

        try {
            await updateAdvertisingContent(advertisingContentData.id, payload);
            const activePath = orgId ? `/orgs/${orgId}` : '';
            navigate(`${activePath}/advertising/contents`);
        } catch (err) {
            // try again...
        }
    };

    return (
        <>
            <AdvertisingContentForm
                content={advertisingContentData}
                control={control}
                handleSubmit={handleSubmit}
                onSubmit={onSubmit}
                errors={errors} // client-side validation
                error={error} // server-side error
                loading={loading}
                isEdit={true}
                file={file}
                setFile={setFile}
                setValue={setValue}
            />
        </>
    );
};

export default UpdateAdvertisingContent;
